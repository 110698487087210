import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Button,
  Grid,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useFirestore } from "react-redux-firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_STYLE } from "../../../../../utils/constants";
import { GREEN, RED } from "../../../../../constants/colors";
import LoadingCircle from "./CookieLoadingCircle";

type SentSourceObject = {
  cType: string;
  cSource: string;
  cSourceHash: string;
  jAttributes: string;
};

type SourceAssignment = {
  cServiceIdString: string;
  cServiceId: string;
  inSavingProcess: boolean;
  inDeletingProcess: boolean;
};

const SourcesDataTraffic = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [sources, setSources] = useState<Array<SentSourceObject>>([]);
  const [services, setServices] = useState<
    { cServiceId: string; cServiceIdString: string }[]
  >([]);
  const [sourceAssignment, setSourceAssignment] =
    useState<Map<any, SourceAssignment>>();
  const firestore = useFirestore();

  const itemsPerPage = 50;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const shownSources = sources.slice(startIndex, endIndex);

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleParentServiceIdStringChange = (
    event: ChangeEvent<{ value: unknown }>,
    cSourceHash: any
  ) => {
    for (let i = 0; i < services.length; i++) {
      if (event.target.value === services[i].cServiceIdString) {
        let newMap = new Map(sourceAssignment);
        newMap.set(cSourceHash, {
          cServiceIdString: services[i].cServiceIdString,
          cServiceId: services[i].cServiceId,
          inDeletingProcess: false,
          inSavingProcess: false,
        });
        setSourceAssignment(newMap);
        break;
      }
    }
  };

  const fetchSources = () => {
    try {
      const collectionRef = firestore
        .collection("plugin")
        .doc("ws5_eucookie")
        .collection("dataTrafficSources");

      collectionRef
        .get()
        .then((querySnapshot) => {
          const sourcesData: SentSourceObject[] = [];
          const sourcesAssignmentCache = new Map<any, SourceAssignment>();

          querySnapshot.forEach((doc) => {
            if (doc.exists) {
              const sourceData = doc.data();
              if (
                sourceData.cType &&
                sourceData.cSource &&
                sourceData.cSourceHash &&
                sourceData.cSourceHash &&
                sourceData.jAttributes
              ) {
                const sourceObj: SentSourceObject = {
                  cType: sourceData.cType,
                  cSource: sourceData.cSource,
                  cSourceHash: sourceData.cSourceHash,
                  jAttributes: sourceData.jAttributes,
                };
                sourcesData.push(sourceObj);
                sourcesAssignmentCache.set(sourceObj.cSourceHash, {
                  cServiceIdString: "",
                  cServiceId: "",
                  inSavingProcess: false,
                  inDeletingProcess: false,
                });
              } else {
                console.error("Error fetching source from Collection");
              }
            }
          });
          setSources(sourcesData);
          setSourceAssignment(sourcesAssignmentCache);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error getting sources:", error);
        });
    } catch (error) {
      console.error("Error fetching sources: ", error);
    }
  };

  const changeSaveAndDeleteProcessState = (
    cSourceHash: any,
    type: String,
    value: boolean
  ) => {
    let newMap = new Map(sourceAssignment);
    let oldValue = sourceAssignment?.get(cSourceHash);

    switch (type) {
      case "save":
        newMap.set(cSourceHash, {
          cServiceIdString: oldValue?.cServiceIdString ?? "",
          cServiceId: oldValue?.cServiceId ?? "",
          inDeletingProcess: oldValue?.inDeletingProcess ?? false,
          inSavingProcess: value,
        });
        break;
      case "delete":
        newMap.set(cSourceHash, {
          cServiceIdString: oldValue?.cServiceIdString ?? "",
          cServiceId: oldValue?.cServiceId ?? "",
          inDeletingProcess: value,
          inSavingProcess: oldValue?.inSavingProcess ?? false,
        });
        break;
      default:
        console.error(
          "Error changing process state, type must be 'save' or 'delete'"
        );
    }
    setSourceAssignment(newMap);
  };

  const saveSourceIntoMainDataBase = async (
    cType: any,
    cSource: any,
    cSourceHash: any,
    cParentServiceId: any
  ) => {
    try {
      if (cType && cSource && cParentServiceId) {
        const newData = {
          cType: cType,
          cSource: cSource,
          cSourceHash: cSourceHash,
          jAttributes: "{}",
        };
        const sourcesCollection = firestore
          .collection("plugin")
          .doc("ws5_eucookie")
          .collection("services")
          .doc(cParentServiceId)
          .collection("sources");
        await sourcesCollection.add(newData);
      } else {
        console.log("Error saving source to database");
      }
    } catch (error) {
      console.error("Error saving source:", error);
    }
  };

  const deleteSourceFromDataTrafficDatabase = async (cSourceHash: any) => {
    try {
      const collectionRef = firestore
        .collection("plugin")
        .doc("ws5_eucookie")
        .collection("dataTrafficSources");

      collectionRef
        .where("cSourceHash", "==", cSourceHash)
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            const docToDelete = querySnapshot.docs[0];
            docToDelete.ref.delete().then(() => {
              console.log(
                "Deleted source successfully from DataTraffic Collection"
              );
              //remove Source from List
              setSources((prevSources) =>
                prevSources.filter(
                  (source) => source.cSourceHash !== cSourceHash
                )
              );
            });
          } else {
            console.log("No document found with cSourceHash: " + cSourceHash);
          }

          setTimeout(() => {
            changeSaveAndDeleteProcessState(cSourceHash, "delete", false);
          }, 800);
        })
        .catch((error) => {
          console.error(
            "Error deleting source from DataTraffic Collection: ",
            error
          );
        });
    } catch (error) {
      console.error(
        "Error deleting source from DataTraffic Collection:",
        error
      );
    }
  };

  const saveAssignment = async (
    cType: any,
    cSource: any,
    cSourceHash: any,
    parentServiceId: any
  ) => {
    try {
      if (
        parentServiceId !== null &&
        parentServiceId !== undefined &&
        parentServiceId !== ""
      ) {
        changeSaveAndDeleteProcessState(cSourceHash, "save", true);
        await saveSourceIntoMainDataBase(
          cType,
          cSource,
          cSourceHash,
          parentServiceId
        );
        await deleteSourceFromDataTrafficDatabase(cSourceHash);
        setTimeout(() => {
          changeSaveAndDeleteProcessState(cSourceHash, "save", false);
        }, 800);
      }
    } catch (error) {
      console.error("Error saving source assignement");
    }
  };

  const refresh = () => {
    setIsLoading(true);
    fetchSources();
  };

  useEffect(() => {
    fetchSources();
  }, []);

  useEffect(() => {
    const collectionRef = firestore
      .collection("plugin")
      .doc("ws5_eucookie")
      .collection("services");

    collectionRef
      .get()
      .then((querySnapshot) => {
        const servicesData: { cServiceId: string; cServiceIdString: string }[] =
          [];

        querySnapshot.forEach((doc) => {
          if (doc.exists) {
            const serviceData = doc.data();
            const serviceId = doc.id;
            const serviceIdString = serviceData.cServiceIdString;
            const service = {
              cServiceId: serviceId,
              cServiceIdString: serviceIdString,
            };
            servicesData.push(service);
          }
        });

        setServices(servicesData);
        console.log("Services:", servicesData);
      })
      .catch((error) => {
        console.error("Error getting services:", error);
      });
  }, []);

  return (
    <>
      <Grid
        container
        justifyContent="flex-start"
        alignContent="center"
        alignItems="center"
        spacing={1}
        className="w-full"
      >
        <div
          className="refreshButtonWrapper"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p style={{ fontSize: "18px", fontWeight: "lighter" }}>
            Automatisch zugesendete, nicht zugeordnete Quellen der Shops
          </p>
          <Button
            variant="contained"
            onClick={() => refresh()}
            disabled={isLoading}
            style={{
              backgroundColor: isLoading
                ? "rgba(255, 255, 255, 0.12)"
                : "rgb(96, 125, 139)",
              transform: "translateX(4px)",
              color: isLoading ? "black" : "white",
            }}
          >
            Refresh
          </Button>
        </div>
        {!isLoading ? (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>kId</TableCell>
                    <TableCell>Quellentyp</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Zuordnung</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shownSources.length > 0 ? (
                    shownSources.map((data: any, index: any) => {
                      const dataIndex = startIndex + index;
                      return (
                        <TableRow key={index}>
                          <TableCell>{dataIndex}</TableCell>
                          <TableCell>{data.cType}</TableCell>
                          <TableCell>
                            <div
                              style={{
                                maxWidth: "820px",
                                overflow: "auto",
                                scrollbarWidth: "thin",
                              }}
                            >
                              {data.cSource}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div
                              className="assignWrapper"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "20px",
                              }}
                            >
                              <Select
                                label="ParentServiceIdString"
                                value={
                                  sourceAssignment?.get(data.cSourceHash)
                                    ?.cServiceIdString
                                    ? sourceAssignment?.get(data.cSourceHash)
                                        ?.cServiceIdString
                                    : ""
                                }
                                onChange={(event) =>
                                  handleParentServiceIdStringChange(
                                    event,
                                    data.cSourceHash
                                  )
                                }
                              >
                                {services.length > 0 ? (
                                  services
                                    .sort((a: any, b: any) =>
                                      a.cServiceIdString.localeCompare(
                                        b.cServiceIdString
                                      )
                                    )
                                    .map((service: any, index: any) => (
                                      <MenuItem
                                        key={index}
                                        value={service.cServiceIdString}
                                      >
                                        {service.cServiceIdString}
                                      </MenuItem>
                                    ))
                                ) : (
                                  <MenuItem>no ids found</MenuItem>
                                )}
                              </Select>
                              <div style={{ display: "flex" }}>
                                {!sourceAssignment?.get(data.cSourceHash)
                                  ?.inSavingProcess ? (
                                  <Button
                                    onClick={() =>
                                      saveAssignment(
                                        data.cType,
                                        data.cSource,
                                        data.cSourceHash,
                                        sourceAssignment?.get(data.cSourceHash)
                                          ?.cServiceId
                                          ? sourceAssignment?.get(
                                              data.cSourceHash
                                            )?.cServiceId
                                          : ""
                                      )
                                    }
                                    title="Auswahl in die Datenbank speichern"
                                  >
                                    <FontAwesomeIcon
                                      color={GREEN}
                                      size={"xl"}
                                      icon={[ICON_STYLE, "save"]}
                                    />
                                  </Button>
                                ) : (
                                  <div
                                    style={{
                                      width: "63.99px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <LoadingCircle
                                      width={"20px"}
                                      height={"20px"}
                                      border={"2px"}
                                    />
                                  </div>
                                )}
                                {!sourceAssignment?.get(data.cSourceHash)
                                  ?.inDeletingProcess ? (
                                  <Button
                                    onClick={() => {
                                      changeSaveAndDeleteProcessState(
                                        data.cSourceHash,
                                        "delete",
                                        true
                                      );
                                      deleteSourceFromDataTrafficDatabase(
                                        data.cSourceHash
                                      );
                                    }}
                                    title="Quelle aus Liste löschen"
                                  >
                                    <FontAwesomeIcon
                                      color={RED}
                                      size={"xl"}
                                      icon={[ICON_STYLE, "remove"]}
                                    />
                                  </Button>
                                ) : (
                                  <div
                                    style={{
                                      width: "63.99px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <LoadingCircle
                                      width={"20px"}
                                      height={"20px"}
                                      border={"2px"}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "lighter",
                        padding: "10px",
                      }}
                    >
                      Zur Zeit keine Zusendungen vorhanden.
                    </p>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div>
              <Button
                variant="contained"
                disabled={currentPage === 1}
                onClick={handlePreviousPage}
              >
                Previous
              </Button>
              <Button
                variant="contained"
                disabled={endIndex >= sources.length}
                onClick={handleNextPage}
              >
                Next
              </Button>
            </div>
          </>
        ) : (
          <div
            className="loadingCircleWrapper"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <LoadingCircle width={"40px"} height={"40px"} border={"4px"} />
          </div>
        )}
      </Grid>
    </>
  );
};

export default SourcesDataTraffic;
