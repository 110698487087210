import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  Checkbox,
  Grid,
  Link,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Release } from "@dash.bar/types";
import useStyles from "./styles";
import { ExpandMore } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { shortenL } from "../../../../../../utils/funcs";
import { useSnackbar } from "notistack";
import { saveRelease } from "../../../../../../hooks/firestore/usePlugins";
import Confirm from "../../../../../../components/Confirm";
import MDEditor from "@uiw/react-md-editor";
import moment from "moment";
import LoadingWrapper from "../../../../../../components/LoadingWrapper";
import { faCheckCircle } from "@fortawesome/pro-duotone-svg-icons";
import Input from "../../../../../marketingNew/Dashboard/components/InputComponent";
import { ICON_STYLE } from "../../../../../../utils/constants";
import { ReleaseState } from "../../../../../../types/Plugin";
import SelectDialog from "../SelectDialog";

type Props = {
  data: Release & {
    requirements: {
      minPhpVersion: string;
      minShopVersion: string;
      maxShopVersion: string;
    };
    internalChangelog: string;
    isPrivateRelease: boolean;
    releaseState: ReleaseState;
  };
  onUpdateRelease?: (release: Release) => Promise<any>;
  onSaveRelease?: (release: Release) => Promise<any>;
  pluginId: string;
  readOnly?: boolean;
};

const ReleaseListItem = ({
  data,
  onUpdateRelease,
  pluginId,
  readOnly = false,
}: Props) => {
  const theme = useTheme();
  const [changelog, setChangelog] = useState<string | undefined>("");
  const [internalChangelog, setInternalChangelog] = useState<
    string | undefined
  >("");
  const [downloadURL, setDownloadURL] = useState("");
  const [minPhpVersion, setMinPhpVersion] = useState("");
  const [minShopVersion, setMinShopVersion] = useState("");
  const [maxShopVersion, setMaxShopVersion] = useState("");
  const cls = useStyles(theme);
  const [expanded, setExpanded] = useState(false);
  const [edit, setEdit] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [isPrivateRelease, setIsPrivateRelease] = useState<boolean>(false);
  const [releaseState, setReleaseState] = useState<ReleaseState>(
    ReleaseState.Private
  );

  useEffect(() => {
    setChangelog(data.changelog ?? "");
    setInternalChangelog(data.internalChangelog ?? "");
    setReleaseState(data.releaseState ?? ReleaseState.Public);
    setDownloadURL(data.downloadUrl ?? "");
  }, [data.changelog, data.downloadUrl]);

  const handleEdit = (e?: React.MouseEvent) => {
    setEdit((edit) => !edit);
    e?.stopPropagation();
  };
  const handleCancel = (e?: React.MouseEvent) => {
    setEdit(false);
    e?.stopPropagation();
  };

  const handleSave = (e?: React.MouseEvent) => {
    setLoading(true);
    const newRelease = {
      version: data.version,
      changelog: changelog,
      internalChangelog: internalChangelog,
      isPrivateRelease: false,
      releaseState: releaseState,
      downloadUrl: downloadURL,
      requirements: {
        minPhpVersion: minPhpVersion,
        minShopVersion: minShopVersion,
        maxShopVersion: maxShopVersion,
      },
    } as Release & {
      requirements: {
        minPhpVersion: string;
        minShopVersion: string;
        maxShopVersion: string;
      };
      internalChangelog: string;
      isPrivateRelease: boolean;
      releaseState: ReleaseState;
    };

    saveRelease(pluginId, data.version, newRelease)
      .then(() => {
        enqueueSnackbar("Release updated successfully!", {
          variant: "success",
        });
        setEdit(false);
      })
      .catch((e) => enqueueSnackbar(`Error: ${e}`, { variant: "error" }))
      .finally(() => setLoading(false));
    e?.stopPropagation();
  };

  const handleReleaseNew = (value: number) => {
    setReleaseState(value);
    saveRelease(pluginId, data.version, {
      releaseState: value,
    } as Release & {
      requirements: {
        minPhpVersion: string;
        minShopVersion: string;
        maxShopVersion: string;
      };
      internalChangelog: string;
      isPrivateRelease: boolean;
      releaseState: ReleaseState;
    })
      .then(() => {
        enqueueSnackbar("Release state successfully updated", {
          variant: "success",
        });
      })
      .catch((e) => enqueueSnackbar(`Error: ${e}`, { variant: "error" }));
  };

  // const handleRelease = () => {
  //   setIsPrivateRelease(false);
  //   saveRelease(pluginId, data.version, {
  //     isPrivateRelease: false,
  //   } as Release & {
  //     requirements: {
  //       minPhpVersion: string;
  //       minShopVersion: string;
  //       maxShopVersion: string;
  //     };
  //     internalChangelog: string;
  //     isPrivateRelease: boolean;
  //   })
  //     .then(() => {
  //       enqueueSnackbar("Version successfully released!", {
  //         variant: "success",
  //       });
  //     })
  //     .catch((e) => enqueueSnackbar(`Error: ${e}`, { variant: "error" }));
  // };
  // const handleUndoRelease = () => {
  //   setIsPrivateRelease(true);
  //   saveRelease(pluginId, data.version, {
  //     isPrivateRelease: true,
  //   } as Release & {
  //     requirements: {
  //       minPhpVersion: string;
  //       minShopVersion: string;
  //       maxShopVersion: string;
  //     };
  //     internalChangelog: string;
  //     isPrivateRelease: boolean;
  //   })
  //     .then(() => {
  //       enqueueSnackbar("Version successfully released!", {
  //         variant: "success",
  //       });
  //     })
  //     .catch((e) => enqueueSnackbar(`Error: ${e}`, { variant: "error" }));
  // };

  const handleRefresh = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    if (onUpdateRelease) {
      setLoading(true);
      onUpdateRelease(data)
        .then(([changelog, downloadURL]) => {
          const newRelease = {} as Release;
          if (changelog) {
            newRelease.changelog = changelog;
          }
          if (downloadURL) {
            newRelease.downloadUrl = downloadURL;
          }
          saveRelease(pluginId, data.version, newRelease)
            .then(() =>
              enqueueSnackbar("Release updated successfully!", {
                variant: "success",
              })
            )
            .catch((e) => enqueueSnackbar(`Error: ${e}`, { variant: "error" }));
        })
        .catch((e) => enqueueSnackbar(`Error: ${e}`, { variant: "error" }))
        .finally(() => setLoading(false));
    }
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded((prevState) => !prevState)}
    >
      <AccordionSummary
        style={{
          ...((isPrivateRelease === true ||
            releaseState === ReleaseState.Private) && {
            backgroundColor: "#333",
          }),
        }}
        expandIcon={<ExpandMore />}
      >
        <Grid
          container
          alignContent={"center"}
          justifyContent={"space-between"}
          alignItems={"center"}
          style={{
            ...(isPrivateRelease === true && {
              opacity: 0.4,
            }),
          }}
        >
          <Grid item>
            <Typography className={cls.heading}>
              {(data.changelog === undefined ||
                data.changelog.trim() === "" ||
                data?.requirements?.minPhpVersion === undefined ||
                data?.requirements?.minPhpVersion.trim() === "" ||
                data?.downloadUrl === undefined ||
                data?.downloadUrl.trim() === "" ||
                data?.requirements?.minShopVersion === undefined ||
                data?.requirements?.minShopVersion.trim() === "") &&
                (data.isPrivateRelease === undefined ||
                  data.isPrivateRelease === false) && (
                  <>
                    <FontAwesomeIcon
                      icon={[ICON_STYLE, "times-circle"]}
                      color={theme.palette.error.main}
                    />{" "}
                  </>
                )}
              {/* {data.public ? (
                <FontAwesomeIcon
                  icon={[ICON_STYLE, "check-circle"]}
                  color={theme.palette.success.main}
                />
              ) : (
                <FontAwesomeIcon
                  icon={[ICON_STYLE, "times-circle"]}
                  color={theme.palette.error.main}
                />
              )}{" "} */}
              Version {data.version}
              <FontAwesomeIcon
                style={{ marginLeft: 10 }}
                icon={[
                  ICON_STYLE,
                  releaseState === ReleaseState.Private
                    ? "lock"
                    : releaseState === ReleaseState.Internal
                    ? "lock-hashtag"
                    : "lock-open",
                ]}
              />
            </Typography>
          </Grid>
          <Grid item>
            ({moment(data.releaseDate.toDate()).format("DD.MM.YYYY")})
          </Grid>
          <Grid item>
            {expanded && !readOnly && (
              <ButtonGroup>
                {/* {!edit && data.isPrivateRelease === true && (
                  <Confirm
                    title={"Version veröffentlichen (API)"}
                    onConfirm={handleRelease}
                    content={
                      "Nach dem Veröffentlichen wird die Version über die Plugin-API sichtbar"
                    }
                  >
                    {(confirm) => (
                      <Button
                        variant={"outlined"}
                        color={"primary"}
                        className={
                          "MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlined MuiButton-outlinedPrimary"
                        }
                        title={"Release"}
                        onClick={(e: React.MouseEvent) => {
                          e.stopPropagation();
                          e.preventDefault();
                          confirm(true);
                        }}
                      >
                        <FontAwesomeIcon icon={[ICON_STYLE, "lock-open"]} />
                      </Button>
                    )}
                  </Confirm>
                )} */}
                {!edit && (
                  <SelectDialog
                    title={"Version veröffentlichen (API)"}
                    onConfirm={handleReleaseNew}
                    defaultValue={releaseState ?? 0}
                    options={[
                      {
                        icon: `-`,
                        label: (
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              key={`private`}
                              style={{
                                fontSize: 14,
                                fontWeight: "normal",
                              }}
                            >
                              {`Nicht veröffentlichen`}
                            </Typography>
                            <Typography
                              key={`private`}
                              style={{
                                fontSize: 12,
                                fontWeight: "lighter",
                              }}
                            >
                              {`Diese Release nirgendwo anzeigen`}
                            </Typography>
                          </div>
                        ),
                        value: ReleaseState.Private,
                      },
                      {
                        icon: `-`,
                        label: (
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              key={`private`}
                              style={{
                                fontSize: 14,
                                fontWeight: "normal",
                              }}
                            >
                              {`Intern veröffentlichen`}
                            </Typography>
                            <Typography
                              key={`private`}
                              style={{
                                fontSize: 12,
                                fontWeight: "lighter",
                              }}
                            >
                              {`Diese Release ist nur für interne API-Endpunkte sichtbar und kann z.B. im Plugin-Backend angezeigt werden`}
                            </Typography>
                          </div>
                        ),
                        value: ReleaseState.Internal,
                      },
                      {
                        icon: `-`,
                        label: (
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              key={`private`}
                              style={{
                                fontSize: 14,
                                fontWeight: "normal",
                              }}
                            >
                              {`Veröffentlichen`}
                            </Typography>
                            <Typography
                              key={`private`}
                              style={{
                                fontSize: 12,
                                fontWeight: "lighter",
                              }}
                            >
                              {`Diese Release wird veröffentlich und ist für alle Endpunkte verfügbar`}
                            </Typography>
                          </div>
                        ),
                        value: ReleaseState.Public,
                      },
                    ]}
                    content={
                      "Bitte wähle aus, wie dieses Release veröffentlicht werden soll."
                    }
                    okButton="Speichern"
                    cancelButton="Abbrechen"
                  >
                    {(confirm) => (
                      <Button
                        variant={"outlined"}
                        color={"primary"}
                        className={
                          "MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlined MuiButton-outlinedPrimary"
                        }
                        title={"Release"}
                        onClick={(e: React.MouseEvent) => {
                          e.stopPropagation();
                          e.preventDefault();
                          confirm(true);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={[
                            ICON_STYLE,
                            releaseState === ReleaseState.Private
                              ? "lock"
                              : releaseState === ReleaseState.Internal
                              ? "lock-hashtag"
                              : "lock-open",
                          ]}
                        />
                      </Button>
                    )}
                  </SelectDialog>
                )}
                {/* {!edit &&
                  (data.isPrivateRelease === false ||
                    data.isPrivateRelease === undefined) && (
                    <Confirm
                      title={"Version zurückziehen"}
                      onConfirm={handleUndoRelease}
                      content={
                        "Nach dem Zurückziehen wird die Version nicht mehr über die Plugin-API sichtbar sein"
                      }
                    >
                      {(confirm) => (
                        <Button
                          variant={"outlined"}
                          color={"primary"}
                          className={
                            "MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlined MuiButton-outlinedPrimary"
                          }
                          title={"Release"}
                          onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            e.preventDefault();
                            confirm(true);
                          }}
                        >
                          <FontAwesomeIcon icon={[ICON_STYLE, "lock"]} />
                        </Button>
                      )}
                    </Confirm>
                  )} */}
                {!edit && (
                  <Confirm
                    title={"Really synchronize?"}
                    content={"Current URL and Changelog gets overwritten."}
                    onConfirm={handleRefresh}
                  >
                    {(confirm) => (
                      <Button
                        variant={"outlined"}
                        color={"primary"}
                        className={
                          "MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlined MuiButton-outlinedPrimary"
                        }
                        title={"Synchronize"}
                        onClick={(e: React.MouseEvent) => {
                          e.stopPropagation();
                          e.preventDefault();
                          confirm(true);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={[ICON_STYLE, "sync"]}
                          color={theme.palette.primary.main}
                        />
                      </Button>
                    )}
                  </Confirm>
                )}
                {!edit && (
                  <Button color={"primary"} onClick={handleEdit} title={"Edit"}>
                    <FontAwesomeIcon icon={[ICON_STYLE, "edit"]} />
                  </Button>
                )}
                {edit && (
                  <Button onClick={handleSave} title={"Save"}>
                    <FontAwesomeIcon
                      icon={[ICON_STYLE, "save"]}
                      color={theme.palette.success.main}
                    />
                  </Button>
                )}
                {edit && (
                  <Button onClick={handleCancel} title={"Cancel"}>
                    <FontAwesomeIcon
                      icon={[ICON_STYLE, "times"]}
                      color={theme.palette.error.main}
                    />
                  </Button>
                )}
              </ButtonGroup>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <LoadingWrapper loading={loading}>
          <Grid
            container
            spacing={3}
            alignItems={"center"}
            alignContent={"flex-start"}
          >
            {/* <Grid item xs={12}>
              <Grid
                container
                spacing={3}
                justifyContent={"center"}
                alignItems={"center"}
                alignContent={"space-around"}
              >
                <Grid item xs={4} md={2} className={cls.dt}>
                  <Tooltip title={"Privater Release"}>
                    <FontAwesomeIcon
                      icon={[ICON_STYLE, "eye-slash"]}
                      size={"2x"}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={8} md={10}>
                  {edit ? (
                    <Grid
                      container
                      spacing={3}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      alignContent={"flex-start"}
                    >
                      <Grid item xs={2} md={2}>
                        <Checkbox
                          style={{ fontSize: 18, color: "#FFF" }}
                          checked={isPrivateRelease}
                          onChange={() => {
                            setIsPrivateRelease(!isPrivateRelease);
                          }}
                        />
                      </Grid>
                      <Grid item xs={10} md={10}>
                        <Typography
                          key={`isPrivateEdit`}
                          style={{
                            fontSize: 18,
                            fontWeight: "lighter",
                          }}
                        >
                          {`Nicht öffentlicher Release`}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : data.downloadUrl ? (
                    <Typography
                      key={`isPrivate`}
                      style={{
                        fontSize: 18,
                        fontWeight: "lighter",
                      }}
                    >
                      {isPrivateRelease
                        ? `Nicht öffentlicher Release`
                        : `Öffentlicher Release`}
                    </Typography>
                  ) : (
                    <pre>n/a</pre>
                  )}
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item xs={12}>
              <Grid
                container
                spacing={3}
                justifyContent={"center"}
                alignItems={"center"}
                alignContent={"space-around"}
              >
                <Grid item xs={4} md={2} className={cls.dt}>
                  <Tooltip title={"Copy Download URL to clipboard."}>
                    <Button
                      className={"c2c"}
                      data-clipboard-text={data.downloadUrl}
                      disabled={!data.downloadUrl}
                    >
                      <FontAwesomeIcon
                        icon={[ICON_STYLE, "download"]}
                        size={"2x"}
                      />
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item xs={8} md={10}>
                  {edit ? (
                    <TextField
                      fullWidth
                      defaultValue={data.downloadUrl}
                      value={downloadURL}
                      onChange={(e) => setDownloadURL(e.target.value)}
                      label={"Download URL"}
                    />
                  ) : data.downloadUrl ? (
                    <Link target={"_blank"} href={data.downloadUrl}>
                      {shortenL(data.downloadUrl, 100)}
                    </Link>
                  ) : (
                    <pre>n/a</pre>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={3}
                justifyContent={"center"}
                alignItems={"center"}
                alignContent={"space-around"}
              >
                <Grid item xs={4} md={2} className={cls.dt}>
                  <Tooltip title={"Copy Changelog to clipboard."}>
                    <Button
                      className={"c2c"}
                      data-clipboard-text={data.changelog}
                      disabled={!data.changelog}
                    >
                      <FontAwesomeIcon
                        icon={[ICON_STYLE, "clipboard-list"]}
                        size={"2x"}
                      />
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item xs={8} md={10}>
                  {edit ? (
                    <MDEditor
                      className={cls.mdEditor}
                      value={changelog}
                      preview={"live"}
                      onChange={setChangelog}
                    />
                  ) : (
                    <MDEditor.Markdown source={changelog} />
                  )}

                  {/* <ReactMarkdown source={data.changelog}/> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={3}
                justifyContent={"center"}
                alignItems={"center"}
                alignContent={"space-around"}
              >
                <Grid item xs={4} md={2} className={cls.dt}>
                  <Tooltip title={"Copy Internal Changelog to clipboard."}>
                    <Button
                      className={"c2c"}
                      data-clipboard-text={data.internalChangelog}
                      disabled={!data.internalChangelog}
                    >
                      <FontAwesomeIcon
                        icon={[ICON_STYLE, "clipboard-question"]}
                        size={"2x"}
                      />
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item xs={8} md={10}>
                  {edit ? (
                    <MDEditor
                      className={cls.mdEditor}
                      value={internalChangelog}
                      preview={"live"}
                      onChange={setInternalChangelog}
                    />
                  ) : (
                    <MDEditor.Markdown source={internalChangelog} />
                  )}

                  {/* <ReactMarkdown source={data.changelog}/> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={3}
                justifyContent={"center"}
                alignItems={"center"}
                alignContent={"space-around"}
              >
                <Grid item xs={4} md={2} className={cls.dt}>
                  <Tooltip title={"Mindestens benötigte php Version"}>
                    <Button disabled={!data?.requirements?.minPhpVersion}>
                      min. PHP-Version
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item xs={8} md={10}>
                  {edit ? (
                    <Input
                      type={`text`}
                      value={minPhpVersion}
                      onChange={(e) => setMinPhpVersion(e.target.value)}
                    />
                  ) : (
                    <Typography
                      key={`minPhp`}
                      style={{
                        fontSize: 18,
                        fontWeight: "lighter",
                      }}
                    >
                      {data?.requirements?.minPhpVersion || "n/a"}
                    </Typography>
                  )}

                  {/* <ReactMarkdown source={data.changelog}/> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={3}
                justifyContent={"center"}
                alignItems={"center"}
                alignContent={"space-around"}
              >
                <Grid item xs={4} md={2} className={cls.dt}>
                  <Tooltip title={"Mindestens benötigte Shop Version"}>
                    <Button disabled={!data?.requirements?.minShopVersion}>
                      min. Shop-Version
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item xs={8} md={10}>
                  {edit ? (
                    <Input
                      type={`text`}
                      value={minShopVersion}
                      onChange={(e) => setMinShopVersion(e.target.value)}
                    />
                  ) : (
                    <Typography
                      key={`minShop`}
                      style={{
                        fontSize: 18,
                        fontWeight: "lighter",
                      }}
                    >
                      {data?.requirements?.minShopVersion || "n/a"}
                    </Typography>
                  )}

                  {/* <ReactMarkdown source={data.changelog}/> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={3}
                justifyContent={"center"}
                alignItems={"center"}
                alignContent={"space-around"}
              >
                <Grid item xs={4} md={2} className={cls.dt}>
                  <Tooltip title={"Höchstens zulässige Shop Version"}>
                    <Button disabled={!data?.requirements?.maxShopVersion}>
                      max. Shop-Version
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item xs={8} md={10}>
                  {edit ? (
                    <Input
                      type={`text`}
                      value={maxShopVersion}
                      onChange={(e) => setMaxShopVersion(e.target.value)}
                    />
                  ) : (
                    <Typography
                      key={`maxShop`}
                      style={{
                        fontSize: 18,
                        fontWeight: "lighter",
                      }}
                    >
                      {data?.requirements?.maxShopVersion || "n/a"}
                    </Typography>
                  )}

                  {/* <ReactMarkdown source={data.changelog}/> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </LoadingWrapper>
      </AccordionDetails>
    </Accordion>
  );
};

export default ReleaseListItem;
