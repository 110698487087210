import React, { SetStateAction, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Portal,
  Radio,
  RadioGroup,
} from "@material-ui/core";

type Props = {
  children: (
    confirm: React.Dispatch<SetStateAction<boolean>>
  ) => React.ReactNode;
  onConfirm?: (value: number) => void;
  onCancel?: () => void;
  title: React.ReactNode | string;
  content: React.ReactNode | string;
  options: Array<{
    icon: string;
    label: React.ReactNode;
    value: number;
  }>;
  defaultValue?: number;
  okButton?: string;
  cancelButton?: string;
};

const SelectDialog = (props: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [value, setValue] = React.useState<number>(
    props.defaultValue ?? props?.options[0]?.value ?? 0
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(+(event.target as HTMLInputElement).value);
    event?.stopPropagation();
  };

  const handleOK = () => {
    setIsOpen(false);
    if (props.onConfirm !== undefined) {
      props.onConfirm(value);
    } else {
      empty();
    }
  };

  const empty = () => {};

  const handleCancel = () => {
    setIsOpen(false);
    (props.onCancel ?? empty)();
  };

  return !isOpen ? (
    <>{props.children(setIsOpen)}</>
  ) : (
    <>
      {props.children(setIsOpen)}
      <Portal>
        <Dialog
          open={isOpen}
          /*onClose={handleCancel}*/
          PaperProps={{
            onClick: (e) => e.stopPropagation(),
          }}
        >
          <DialogTitle>{props.title}</DialogTitle>
          <DialogContent onClick={(e) => e.stopPropagation()}>
            <DialogContentText>{props.content}</DialogContentText>
            <RadioGroup
              value={value}
              onChange={handleChange}
              defaultValue={props.defaultValue ?? props?.options[0]?.value ?? 0}
            >
              {props.options.map((option, index) => {
                return (
                  <FormControlLabel
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                    id={`${option.value}_${index}`}
                  />
                );
              })}
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCancel} color="primary">
              {props.cancelButton || "cancel"}
            </Button>
            <Button onClick={handleOK} color="primary" autoFocus>
              {props.okButton || "OK"}
            </Button>
          </DialogActions>
        </Dialog>
      </Portal>
    </>
  );
};

export default SelectDialog;
